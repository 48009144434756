import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from 'react-query/devtools';
import { darkTheme, LightTheme } from "./Theme";
import GlobalStyle from './styles/Globalstyle';
import CommonMeta from "./components/CommonMeta";
import Router from "./Router";
import { Route } from "react-router-dom";
import SubTopCont from "./components/subTopCont";

function App() {
  return (
    <>
      <ThemeProvider theme={LightTheme}>
        <GlobalStyle />
        <Router>
          <Route path="/sub-page" element={<SubTopCont />} />
        </Router>
        <ReactQueryDevtools initialIsOpen={true} />
      </ThemeProvider>
    </>
  );
}

export default App;