import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import menuData from '../data/menu.json';
import CommonMeta from './CommonMeta';

interface SubMenu {
  name: string;
  subname?: string;
  path: string;
  alert?: string;
}
interface MenuItem {
  name: string;
  subname?: string;
  path: string;
  alert?: string;
  submenu?: SubMenu[];
}
interface HeaderProps {
  menuData: MenuItem[];
}

const Header = ({ menuData }: HeaderProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
  const navigate = useNavigate();

  const mouseEnter = () => {
    setIsHovered(true);
  };
  const mouseLeave = () => {
    setIsHovered(false);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    alertMessage?: string
  ) => {
    if (alertMessage) {
      event.preventDefault(); // 페이지 이동 막기
      alert(alertMessage);
    }
  };

  const handleSubMenuClick = (name: string) => {
    if (activeSubMenu === name) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(name);
    }
  };
  const handleFirstSubmenuClick = (menuItem: MenuItem) => {
    if (menuItem.submenu && menuItem.submenu.length > 0) {//하위메뉴 있는지 확인
      let firstSubmenu = menuItem.submenu[0];//조건에 따라 변수를 재할당해야하므로 let사용
      if (firstSubmenu.alert) {
        firstSubmenu = menuItem.submenu.find(subItem => !subItem.alert) || firstSubmenu;
      }//subitem에 alert이 없는걸찾아보고 alert이 없다면 firstmenu로 이동
      navigate(firstSubmenu.path, { state: { name: menuItem.name, subname: firstSubmenu.subname } });
    }
  };

  return (
    <>
      <CommonHeader
        className={isHovered ? 'on' : ''}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        <Logo>
          <Link to="/"></Link>
        </Logo>
        <Nav>
          <Depth1>
            {menuData.map((menuItem) => (
              <li key={menuItem.name}>
                <Link
                  to={menuItem.submenu ? '#' : menuItem.path}
                  onClick={(e) => {
                    if (menuItem.submenu) {
                      e.preventDefault(); // 페이지 이동 막기
                      handleFirstSubmenuClick(menuItem);
                    } else {
                      handleClick(e, menuItem.alert);
                    }
                  }}
                >
                  {menuItem.subname}
                </Link>
                {menuItem.submenu && (
                  <Depth2 style={{ height: isHovered ? 'auto' : '0' }}>
                    {menuItem.submenu.map((subItem) => (
                      <li key={subItem.name}>
                        <Link
                          to={subItem.path}
                          onClick={(e) => handleClick(e, subItem.alert)}
                        >
                          {subItem.subname}
                        </Link>
                      </li>
                    ))}
                  </Depth2>
                )}
              </li>
            ))}
          </Depth1>
        </Nav>
        <SideNav className={isMenuOpen ? 'active' : ''}>
          <SideDepth1>
            {menuData.map((menuItem) => (
              <li key={menuItem.name}>
                <a
                  href="#"
                  onClick={() => handleSubMenuClick(menuItem.name)}
                >
                  {menuItem.subname}
                </a>
                {menuItem.submenu && (
                  <SideDepth2
                    className={
                      activeSubMenu === menuItem.name ? 'open' : ''
                    }
                  >
                    {menuItem.submenu.map((subItem) => (
                      <li key={subItem.name}>
                        <Link
                          to={subItem.path}
                          onClick={(e) => handleClick(e, subItem.alert)}
                        >
                          {subItem.subname}
                        </Link>
                      </li>
                    ))}
                  </SideDepth2>
                )}
              </li>
            ))}
          </SideDepth1>
        </SideNav>

        <HeaderRight>
          <MenuButton
            className={isMenuOpen ? 'active' : ''}
            aria-label="메뉴"
            onClick={toggleMenu}
          >
            <i className="menu-button__line"></i>
          </MenuButton>
        </HeaderRight>
        <HeaderBg style={{ height: isHovered ? '190px' : '0' }} />
      </CommonHeader>

      <SideR>
        <Link to="http://pf.kakao.com/_mMVxoG/chat" target='_blank'><span className="blind">카카오톡 채널 바로가기</span><i>카카오톡채널 바로가기 아이콘</i></Link>
      </SideR>
    </>
  );
};

const CommonHeader = styled.header`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100px;
  background-color: ${props => props.theme.bgColor};
  padding: 0 4vw 0;
  transition: height ease-in-out 0.4s 0s, background-color ease-in-out 0.4s 0s;
  box-sizing: border-box;
  @media screen and (max-width:768px) {
    height: 70px;
  }
`
const Nav = styled.nav`
  height:100%;
`
const Depth1 = styled.ul`
  display: flex;
  height: 100%;
  align-items: center;
  & > li {
      position: relative;
      padding: 20px 1.8vw 23px;
    & > a {
      font-size: 18px;
      color: ${props => props.theme.textColor};
      font-weight: 400;
      position: relative;
      text-align: center;
    }
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
`
const Depth2 = styled.ul`
  position: absolute;
  width: 100%;
  top: 95px;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  li {
    padding-top: 20px;
    line-height: 1;
    a {
      color: #fff;
      font-size: 16px;
    }
  }
`

const SideNav = styled(Nav)`
  position: fixed;
  opacity: 0;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.bgColor};
  transition: opacity 0.6s ease-in, left 0.6s ease-in;
  &.active {
    opacity: 1;
    left: 0;
  }
`
const SideDepth1 = styled.ul`
  width: 100%;
  padding: 15vw 0;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background-image: url(/assets/images/common/all-menu-bg.png);
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  & > li {
      position: relative;
      padding: 20px 1.8vw 23px;
    & > a {
      font-size: 20px;
      color: #fff;
      font-weight: 400;
      position: relative;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 15vw 6vw;
  }
`
const SideDepth2 = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  font-weight: 300;
  margin-top: 3.8vh;
  font-size: 18px;
  & > li {
    margin-bottom: 14px;
    position: relative;
    margin-right: 30px;
    & > a {
      font-size: 18px;
      color: #fff;
      font-weight: 400;
      position: relative;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    opacity: 0;
    height: 0;
    visibility: hidden;
    margin: 0;
    transform: translateY(-50%);
    transition: transform .8s ease, opacity .8s ease, margin .8s ease, height 2.2s ease;
    &.open {
      transform: translateY(0);
      opacity: 1;
      height: auto;
      visibility: visible;
      margin-top: 3vh;
    }
  }
`
const Logo = styled.h1`
  display: block;
  width: 238px;
  height: 51px;
  a {
    display: block;
    width: 100%;
    height: 100%;
    background: url('../assets/images/common/logo.png') 0 0 no-repeat;
    background-size: 100% auto;
  }
  @media screen and (max-width:768px) {
    width: 100%;
    height: 27px;
    a {
      background-size: contain;
    }
  }
`
const HeaderRight = styled.div`
  display: flex;
  margin-left: 192px;
`
const MenuButton = styled.button`
  position: relative;
  z-index: 1111;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  .menu-button__line {
    display: block;
    width: 18px;
    height: 2px;
    margin: 0 auto 0;
    background: ${(props) => props.theme.textColor};
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .menu-button__line::before,
  .menu-button__line::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background: ${(props) => props.theme.textColor};
    display: block;
    -webkit-transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    -moz-transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    -o-transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  .menu-button__line::before {
    top: 10px;
  }
  .menu-button__line::after {
    top: 28px;
  }
  &.active {
    .menu-button__line {
      background-color: rgba(0, 0, 0, 0);
    }
    .menu-button__line::before {
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      background-color: #fff;
      top: 20px;
    }
    .menu-button__line::after {
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
      background-color: #fff;
      top: 20px;
    }   
  }
`;
const HeaderBg = styled.div`
  opacity: 1;
  z-index: -1;
  width: 100%;
  height: 0;
  min-height: 0;
  background-color: rgba(46, 40, 104);
  background-repeat: no-repeat;
  background-position: 95% 80%;
  position: absolute;
  top: 100px;
  left: 0;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width:768px) {
    display: none;
  }
`
const SideR = styled.div`
  position: fixed;
  right:50px;
  bottom:50px;
  width: 60px;
  height: 60px;
  white-space: nowrap;
  border-radius: 30px;
  background-color: #fee500;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
  animation: scaleUp 0.3s cubic-bezier(0, 0, 0.75, 1.5);
  z-index:10;
  a {
    display: flex;
    justify-content:center;
    align-items:center;
    height:100%;
  }
  i {
    overflow: hidden;
    display: inline-block;
    width:44px;
    height:44px;
    line-height: 0;
    font-size: 0;
    background: url('/assets/images/common/ico_kakao.png') 50% 50%/36px no-repeat;
    vertical-align: top;
    text-indent: -9999px;
  }
`;
export default Header;
