import styled from "styled-components"

export const Section = styled.div`
  position: relative;
`;
export const SectionHeader = styled.header`
  text-align: center;
  margin: auto;
  color: #777777;
  padding-bottom: 100px;
  @media screen and (max-width:768px) {
    padding-bottom: 0;
  }
`
export const Impact = styled.span`
  color: ${props=> props.theme.mainColor};
  font-size: 14px;
  font-weight: 500;
`
export const Line = styled.b`
  display: block;
  content: "";
  width: 50px;
  height: 1px;
  background-color: ${props=> props.theme.mainColor};
  margin: 25px auto 25px;
`
export const Tit = styled.h2`
  font-size: 48px;
  font-family: "minerva-modern", "NotoSansKR", sans-serif, "Malgun Gothic", "맑은 고딕";
  font-weight: 600;
  line-height: 1.21666;
  color: ${props=> props.theme.mainColor};
  text-transform: uppercase;
  text-align: center;
  margin: auto;   
`
export const Desc = styled.p`
  /* opacity: 0;
  transform: translateX(-3vw); */
  transition: all .8s ease;
  padding-bottom: 50px;
`
export const Link = styled.a`
  
`
export const Box = styled.div`
  
`
export const TextArea = styled.div`

`
