import React, { forwardRef, useEffect, useRef } from "react";
import styled from "styled-components";
import { Box } from "./styles/commonStyles";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ListData = [
  {
    title: "electricity",
    subtitle: "전기",
    desc: "대산파워텍은 20년간 전기를 전문분야로 <br>많은 고객들에게 솔루션을 제공하고 있습니다.",
    img1: "/assets/images/main/field-img1.png",
    img2: "/assets/images/main/field-img1-big.png",
  },
  {
    title: "fire",
    subtitle: "소방설비",
    desc: "대산파워텍은 전문소방설비 공사업을 등록하여<br> 다양한 서비스를 제공하고 있습니다.",
    img1: "/assets/images/main/field-img2.png",
    img2: "/assets/images/main/field-img2-big.png",
  },
  {
    title: "sunlight",
    subtitle: "태양광",
    desc: "대산파워텍은 신.재생에너지설비 전문기업입니다.",
    img1: "/assets/images/main/field-img3.png",
    img2: "/assets/images/main/field-img3-big.png",
  }
];

const FieldSection = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const fieldSectionRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const handleResize = () => {
      const isDesktop = window.matchMedia('(min-width: 1200px)').matches;
      if (fieldSectionRef.current) {
        fieldSectionRef.current.classList.toggle('desktop', isDesktop);
        fieldSectionRef.current.classList.toggle('mobile', !isDesktop);
      }

      ScrollTrigger.refresh();
    };

    resizeObserver.current = new ResizeObserver(handleResize);
    if (fieldSectionRef.current) {
      resizeObserver.current.observe(document.body);
    }

    handleResize();

    const fieldListTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.field-list',
        start: 'top top',
        end: 'bottom bottom',
        toggleActions: 'play none none reverse',
        toggleClass: { targets: '.field-list', className: 'ani-active' },
      },
    });

    const listElem = gsap.utils.toArray<HTMLElement>('.field-item');
    listElem.forEach((elem, i) => {
      const imgArea = elem.querySelector(".img-area");
      const title = elem.querySelector(".title");
      const subtitle = elem.querySelector(".subtitle");
      const desc = elem.querySelector(".desc-area");
      const fieldItemTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: elem,
          start: 'top 50%',
          end: 'bottom 50%',
          scrub: true,
          toggleActions: "play none none reverse",
        },
      });

      if (window.matchMedia('(min-width: 1200px)').matches) {
        if (i !== 0) { // 첫 번째 아이템이 아닌 경우에만 모션 적용
          fieldItemTimeline.fromTo(imgArea, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'power2.out' });
        }
      } else {
        fieldItemTimeline.fromTo(imgArea, { opacity: 0, translateY: '30px' }, { opacity: 1, translateY: '0px', duration: 0.5, ease: 'power1.out' });
      }

      fieldItemTimeline.fromTo(title, { opacity: 0, translateY: '30px' }, { opacity: 1, translateY: '0px', duration: 0.5, ease: 'power1.out' }, '<');
      fieldItemTimeline.fromTo(subtitle, { opacity: 0, translateY: '30px' }, { opacity: 1, translateY: '0px', duration: 0.5, ease: 'power1.out' }, '<');
      fieldItemTimeline.fromTo(desc, { opacity: 0, translateY: '30px' }, { opacity: 1, translateY: '0px', duration: 0.5, ease: 'power1.out' }, '<');

      fieldListTimeline.add(fieldItemTimeline, 0);
    });

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <FieldWrap ref={fieldSectionRef} className="field-wrap">
      <FieldList className="field-list">
        {ListData.map((item, index) => (
          <FieldItem key={index} className="field-item">
            <ImgArea className="img-area">
              <img src={item.img1} alt={`${item.title} 1`} className="small" />
              <img src={item.img2} alt={`${item.title} 2`} className="big" />
            </ImgArea>
            <TextArea>
              <Box>
                <FieldItemTitle className="title">{item.title}</FieldItemTitle>
                <FieldItemSubtitle className="subtitle">{item.subtitle}</FieldItemSubtitle>
                <FieldItemDesc className="desc-area" dangerouslySetInnerHTML={{ __html: item.desc }} />
              </Box>
            </TextArea>
          </FieldItem>
        ))}
      </FieldList>
    </FieldWrap>
  );
});

const TextArea = styled.div`
  position: relative;
  @media screen and (min-width: 768px) {
    // 768px 이상일 때 적용할 스타일.
  }

  @media screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-left: auto;
  }

  ${Box} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 70px;
    letter-spacing: -0.5px;
    color: #000000;
    font-weight: bold;

    @media screen and (min-width: 768px) {
      margin-top: 100px;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 0;
    }
  }
`;

const ImgArea = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 60px;
  width: 100%;

  @media screen and (min-width: 1200px) {
    width: 50%;
    height: 100%;
    padding: 0;
    &:not(:first-child) {
      opacity: 0;
    }
  }

  img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;

    @media screen and (max-width: 1200px) {
      padding-top: 0;
      height: 100%;
    }
    &.small {
      @media screen and (max-width: 1199px) {
        display: none !important;
      }
    } 
    &.big {
      @media screen and (min-width: 1200px) {
        display: none !important;
      }
    }
  }
`;

const FieldItemTitle = styled.h3`
  font-size: 68px;
  text-transform: uppercase;
  font-family:"minerva-modern";
  color: rgba(0,0,0,.35);
  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

const FieldItemSubtitle = styled.h4`
  font-size: 38px;
  margin-bottom: 50px;
  color: rgba(0,0,0,.35);
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const FieldItemDesc = styled.p`
  font-size: 22px;
  text-align: center;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const FieldItem = styled.div`
  margin-bottom: 80px;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin-bottom: 0;
  }
`;

const FieldList = styled.div`
  &.ani-active {
    ${FieldItem} {
      @media screen and (min-width: 1200px) {
        flex-direction: row;
      }
    }

    ${ImgArea} {
      @media screen and (min-width: 1200px) {
        position: fixed;
        left: 0;
        right: auto;
        top: 0px;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
      }
    }
  }
`;

const FieldWrap = styled.div`
  width: 100%;
  height: 100%;
`;

export default FieldSection;
