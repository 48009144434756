import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;600;700;800;900&display=swap');
  @import url("https://use.typekit.net/bsi6uxz.css");
  @import url("https://use.typekit.net/sod2toi.css");
  @import url("https://use.typekit.net/cxo6lxl.css");
  
  @font-face {
    font-family: 'KyoboHandwriting2023wsa';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404-2@1.0/KyoboHandwriting2023wsa.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  font-size: 16px;
}
@media all and (max-width: 910px) {
  html {
    font-size: 14px;
  }
}

a,
a:hover,
a:focus,
a:active,
a:link {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

img {
  border: 0;
  vertical-align: middle;
  border: 0 none; 
  font-size: 0;
  line-height: 0;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0;
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}
button:focus {
  outline: none;
}

strong,
b {
  color: inherit;
  font-size: inherit;
}

i,
em {
  font-style: normal;
}

mark {
  color: inherit;
  background: none;
}

button {
  -webkit-appearance: none;
}

input {
  -webkit-border-radius: 0;
}

input:matches([type=password], [type=search]),
input[type=text],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
table {
  border-collapse: collapse;
}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family:"Noto Sans KR", "futura-pt" ,"montserrat" ;
    background-color: ${props => props.theme.bgColor};
    color: ${(props) => props.theme.textColor};
    line-height: 1.2;
  }
  a {
    text-decoration: none;
    color:inherit;
  }
  @media screen and (min-width:769px) {
    .is--pc {
      display:block !important;
    }
    .is--mobile {
      display:none !important;
    }
  }
  @media screen and (max-width:768px) {
    .is--pc {
      display:none !important;
    }
    .is--mobile {
      display:block !important;
    }
  }
  .blind {
    display: none !important;
  }
  caption {
    display: none !important;
  }
`;

export default GlobalStyle;