import React, { forwardRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Map, MapMarker, CustomOverlayMap  } from 'react-kakao-maps-sdk';
import { SectionHeader, Impact, Line, Tit, Desc, Link, TextArea } from './styles/commonStyles';
interface LocationProps {
  LocationWrap?: React.ElementType;
}

const LocationSection = forwardRef<HTMLDivElement, LocationProps>(
  ({ LocationWrap = DefaultLocationWrap }, ref) => {
    const [map, setMap] = useState<kakao.maps.Map | null>(null);
    const mapCenter = { lat: 37.487441176027886, lng: 126.6524325741473 };

    useEffect(() => {
      const handleResize = () => {
        if (map) {
          map.setCenter(new kakao.maps.LatLng(mapCenter.lat, mapCenter.lng));
        }
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [map]);

    return (
      <LocationWrap ref={ref}>
        <SectionHeader className="section-header">
          <Impact>오시는길</Impact>
          <Line></Line>
          <Tit>location</Tit>
          <Desc>
            (주)대산파워텍으로 찾아오시는 길입니다.
          </Desc>
          <LocationLink href="https://naver.me/GvdFYl7M" target="_blank" className="is--pc">
            <img src="/assets/images/main/location_btn1.png" alt="" />
          </LocationLink>
          <LocationLink href="https://kko.to/nvTIwtIpx7" target="_blank" className="is--pc">
            <img src="/assets/images/main/location_btn2.png" alt="" />
          </LocationLink>
        </SectionHeader>
        <LocationInner>
          <Box>
            <LocationMap 
              center={mapCenter}
              level={3}
              onCreate={setMap}  // Map 생성 시 setMap을 호출하여 map 상태를 설정
            >
            <LocationCustomOverlayMap position={{ lat: 37.487441176027886, lng: 126.6524325741473 }}>
              <Overlay>대산파워텍</Overlay>
            </LocationCustomOverlayMap>
            </LocationMap>
            <TextArea>
              <ul>
                <li>
                  <p>위치</p>
                  <span>인천광역시 동구 방축로37번길 30, 산업유통센터 편익상가A동B19호</span>
                </li>
              </ul>
            </TextArea>
          </Box>
          <MLocationLink href="https://naver.me/GvdFYl7M" target="_blank" className="location__link location-box is--mobile">
            <img src="/assets/images/main/location_btn1.png" alt="" />
          </MLocationLink>
          <MLocationLink href="https://kko.to/nvTIwtIpx7" target="_blank" className="location__link location-box is--mobile">
            <img src="/assets/images/main/location_btn2.png" alt="" />
          </MLocationLink>
        </LocationInner>
      </LocationWrap>
    );
  }
);
export const LocationMap = styled(Map)`
  width: 100%;
  height: 600px;
`
export const LocationCustomOverlayMap = styled(CustomOverlayMap)`
  
`
export const Overlay = styled.div`
  position: relative;
  width: 80px;
  height: 25px;
  background-color: #2e2868;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LocationLink = styled(Link)`
  transition: all .8s ease;
  display: block;
  margin-bottom: 10px;
  @media (max-width: 768px) {  
    margin: 0;
    padding: 10px 6vw 0;
  }
`;
export const MLocationLink = styled(LocationLink)`
  display: none;
  &.is--mobile {
    @media (max-width: 768px) {  
      display: block;
    }
  }
  @media (max-width: 768px) {  
    width: 80%;
    img {
      width: 100%;
    }
  }
`;
export const Box = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;
export const LocationInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${Box} {
    opacity: 0;
    transform: translateX(3vw);
    transition: all 1.2s ease;
    width: 100%;
    ${TextArea} {
      ul {
        li {
          display: flex;
          padding-top: 10px;
          p {
            display: flex;
            align-items: center;
            width: 90px;
            font-size: 20px;
            color: #172d53;
            box-sizing: border-box;
            letter-spacing: -0.05em;
            font-weight: bold;
            &::before {
              content: "";
              display: inline-block;
              width: 3px;
              height: 100%;
              margin-right: 15px;
              background-color: #172d53;
              @media screen and (max-width:768px) {
                margin-right: 8px;
              }
            }
            @media screen and (max-width:768px) {
              width: 50px;
              font-size: 14px;
            }
          }
          span {
            padding-top: 3px;
            @media screen and (max-width:768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`;
export const DefaultLocationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  padding:  100px 0 100px 8vw;
  background-image: url(../images/main/location-bg.png);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  overflow: hidden;
  ${SectionHeader} {
    opacity: 0;
    transform: translateX(-3vw);
    transition: all 1.2s ease;
  }
  &.is-active {
    ${Box} {
      opacity: 1;
      transform: translate(0);
      transition-delay: .5s;
    }
    ${SectionHeader} {
      opacity: 1;
      transform: translate(0);
    }
  }
  @media screen and (max-width:768px) {
    flex-direction: column;
    gap: 5vw;
    padding: 10vw 6vw;
  }
`;


export default LocationSection;
