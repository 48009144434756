import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import menuData from '../data/menu.json';
import { MenuData } from './../interfaces/menuInterface';

interface CommonMetaProps {
  title?: string;
}

interface RouteState {
  name?: string;
  subname?: string;
}

// 현재 경로에 해당하는 메뉴의 이름들을 찾기
const findMenuNames = (path: string): { name: string; subname: string } => {
  for (const menu of menuData as MenuData[]) {
    if (menu.path === path) {
      return { name: menu.name, subname: menu.subname || '' };
    }
    for (const submenu of menu.submenu || []) {
      if (submenu.path === path) {
        return { name: submenu.name, subname: submenu.subname || '' };
      }
    }
  }
  return { name: "", subname: "" };
};

const CommonMeta = ({ title }: CommonMetaProps) => {
  const location = useLocation();
  const state = location.state as RouteState | undefined;
  const defaultName = '대산파워텍';

  const currentPath = location.pathname;
  const { subname } = state || findMenuNames(currentPath);

  // 사용자가 정의한 타이틀이 있는 경우 그것을 사용하고, 없으면 메뉴에서 찾은 이름을 사용
  const pageTitle = title || `${defaultName} - ${subname}`;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="keywords" content={pageTitle} />
      <meta name="application-name" content={pageTitle} />
      <meta name="msapplication-tooltip" content={pageTitle} />
      <meta id="meta_og_title" property="og:title" content={pageTitle} />
      <meta name="description" content={pageTitle} />
      <meta property="og:description" content={pageTitle} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="theme-color" content="#000000" />
      <meta name="msapplication-navbutton-color" content="#000000" />
      <link rel="shortcut icon" href="/assets/images/common/favicon.ico" type="image/x-icon" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta property="og:image" content="/assets/images/common/og-image.png" />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="627" />
      <link rel="stylesheet" href="https://use.typekit.net/ath8ztl.css"></link>
    </Helmet>
  );
};

export default CommonMeta;
