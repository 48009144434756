import styled from "styled-components";

const Login = () => {
  return (
    <Form className="admin-form">
      <h2>
        <a href="/">
          <img src="/assets/images/common/logo.png" alt="Logo" />
        </a>
      </h2>
      <LoginArea className="login-area">
        <LoginBox>
          <span>아이디</span>
          <input type="text" className="input__text" placeholder="아이디 입력" />
          <span>비밀번호</span>
          <input type="password" className="input__text" placeholder="비밀번호 입력" />
          <button>로그인</button>
        </LoginBox>
      </LoginArea>
    </Form>
  )
}
const Container = styled.div`

`
const Form = styled.form`
  background-color: #ecf0f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  h2 {
    text-align: center;
    padding-bottom: 30px;
  }
`
const LoginArea = styled.div`
  padding: 20px 30px;
  background-color: #fff;
`
const LoginBox = styled.div`
  padding: 30px 20px;
  border: 1px solid #ddd;
  span {
    font-size: 12px;
    padding: 0 5px;
    color: #333;
  }
  input {
    height: 30px;
    padding: 0 5px;
    line-height: 30px;
    background: #fff;
    border: 1px solid #ddd;
  }
  button {
    padding: 0 30px;
    height: 30px;
    line-height: 30px;
    background-color: #2e2868;
    color: #fff;
    margin-left: 5px;
  }
`

export default Login;