import React, { forwardRef } from "react";
import styled from "styled-components";
import { Section } from './styles/commonStyles';

const InfoSection = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <InfoWrap ref={ref}>
      <header>
        <h2>Daesan Power Tech</h2>
        <b className="line"></b>
        <span>
          <b>고객을 1순위</b>로 생각하는<br className="is--mobile" /> 대산파워텍입니다.
        </span>
        <p>
          (주)대산파워텍은 고객사와의 <br className="is--mobile" />적극적이고 원활한 소통과<br className="is--pc" /> 차별화된 전문성을 바탕으로<br className="is--mobile" /> 고객과의 약속을 지켜나가겠습니다.
        </p>
      </header>
    </InfoWrap>
  )
})
const InfoWrap = styled(Section)`
  padding: 250px 0;
  background-image: url('/assets/images/main/info_pattern.png');
  background-size: contain;
  background-position: 130% 100%;
  background-color: #fafafb;
  background-repeat: no-repeat;
  background-attachment: fixed;
  header {
    text-align: center;
    margin: auto;
    color: ${props => props.theme.textColor};
    padding-bottom: 100px;
    h2 {
      opacity: 0;
      transition: all 1.2s ease;
      font-size: 58px;
      font-family: "minerva-modern", "NotoSansKR", "Malgun Gothic", "맑은 고딕", sans-serif;
      font-weight: 500;
      text-align: center;
      margin: auto;
      color: ${props => props.theme.textColor};
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }
    .line {
      display: block;
      content: "";
      width: 50px;
      height: 1px;
      background-color: ${props => props.theme.textColor};
      margin: 25px auto 25px;
    }
    span {
      display: block;
      opacity: 0;
      transform: translateY(3vw);
      transition: all 1.2s ease;
      font-size: 48px;
      font-weight: 600;
      line-height: 1.21666;
      color: ${props => props.theme.textColor};
      text-transform: uppercase;
      text-align: center;
      margin: auto;
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    p {
      opacity: 0;
      padding-top: 50px;
      font-size: 22px;
      transform: translateY(3vw);
      transition: all 1.2s ease;
      @media screen and (max-width: 768px) {
        padding-top: 30px;
        font-size: 16px;
      }
    }
  }
  &.is-active {
    header {
      h2 {
        opacity: 1;
        transform: translate(0);
      }
      span {
        opacity: 1;
        transform: translate(0);
        transition-delay: .5s;
      }
      p {
        opacity: 1;
        transform: translate(0);
        transition-delay: 1s;
      }
    }
  }
`;
export default InfoSection;