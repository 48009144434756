import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense, ReactNode } from "react";
import Main from "./routes/Main";
import Login from "./pages/admin/login";
import menuData from './data/menu.json';
import AdminmenuData from './data/admin.json'

interface RouterProps {
  children?: ReactNode;
}

// Lazy import component based on path
const lazyImport = (path: string) => {
  const Component = lazy(() => import(`.${path}`));
  return <Component />;
};

function Router({ children }: RouterProps) {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/admin" element={<Login />} />

          {menuData.map((menu) =>
            menu.submenu ? (
              menu.submenu.map((sub) => (
                <Route key={sub.path} path={sub.path} element={lazyImport(`/pages/sub/${menu.name}/${sub.name}.tsx`)} />
              ))
            ) : (
              <Route key={menu.path} path={menu.path} element={lazyImport(`/pages${menu.path}.tsx`)} />
            )
          )}
          {AdminmenuData.map((adminMenu) =>
            adminMenu.submenu ? (
              adminMenu.submenu.map((sub) => (
                <Route key={sub.path} path={sub.path} element={lazyImport(`/pages/${adminMenu.name}/${sub.name}.tsx`)} />
              ))
            ) : (
              <Route key={adminMenu.path} path={adminMenu.path} element={lazyImport(`/pages${adminMenu.path}.tsx`)} />
            )
          )}
          {children}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
