import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Footer = () => {
  return (
    <CommonFooter>
      <FooterTextArea>
        <PointText>
          주소 : 인천광역시 동구 방축로37번길 30, 산업유통센터 편익상가A동B19호
          <Separator />
          대표이사: 조기영
          <Separator />
          사업자번호 : 137-86-14339
        </PointText>
        <Text>
          ※ 홈페이지 제작에 사용된 이미지 및 내용 등은 소비자의 이해를 돕기 위한 것으로 실제와 차이가 있을 수 있습니다.<br />
          ※ 홈페이지의 개발계획 및 예정사항 등은 관계 기관의 사정에 따라 지연, 취소, 변경될 수 있음을 유의하시기 바라며 이는 시행사 및 시공사와는 무관합니다.
        </Text>
        <Copyright>
          COPYRIGHT(C) (주)대산파워텍. ALL RIGHTS RESERVED
        </Copyright>
      </FooterTextArea>
    </CommonFooter>
  );
};
const CommonFooter = styled.footer`
  background: #2e2868;
  padding: 30px 65px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  @media screen and (max-width:768px) {
    padding: 30px 6vw 30px;
    p {
      font-size: 12px;
    }
  }
`
const FooterTextArea = styled.div`

`
const Text = styled.p`
  font-size: 14px;
  color: rgba(255, 255, 255, .7);
  letter-spacing: -0.5px;
  margin: 17px 0 10px;
`
const PointText = styled(Text)`
  margin: 0;
`
const Separator = styled.span`
  font-size: 14px;
  color: rgba(154, 154, 154, 0.4);
  margin: 0 8px;
`
const Copyright = styled.p`
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Montserrat", "Noto Sans KR", sans-serif, "Malgun Gothic", "맑은 고딕";
`
export default Footer;