import React, { forwardRef, useEffect } from "react";
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperClass from 'swiper';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';

const MainSection = forwardRef<HTMLDivElement>((props, ref) => {
  useEffect(() => {
    // visual-pagination__item 클릭 시 슬라이드 이동
    const paginationItems = document.querySelectorAll('.js-visual-pagination .visual-pagination__item');
    paginationItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        const swiperElement = document.querySelector('.mainSwiper');
        const swiperInstance = (swiperElement as any)?.swiper;
        if (swiperInstance) {
          swiperInstance.slideToLoop(index);
        }
      });
    });

    // 클린업 함수
    return () => {
      paginationItems.forEach((item) => {
        item.removeEventListener('click', () => {});
      });
    };
  }, []);
  
  return (
    <MainWrap ref={ref}>
      <Swiper
        className="swiper-container mainSwiper"
        modules={[Navigation, Pagination, Autoplay, EffectFade]}
        pagination={{ el: '.mainSwiper .swiper-pagination', type: 'fraction', clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        navigation={{ nextEl: '.mainSwiper .swiper-button-next', prevEl: '.mainSwiper .swiper-button-prev' }}
        effect="fade"
        onInit={(swiper: SwiperClass) => {
          const slideLength = swiper.slides.length;
          const totalElement = document.querySelector('.js-swiper-pagination-total') as HTMLElement;
          if (totalElement) {
            totalElement.innerText = (slideLength).toString();
          }
          const firstPaginationItem = document.querySelector('.js-visual-pagination .visual-pagination__item:first-child');
          if (firstPaginationItem) {
            firstPaginationItem.classList.add('is--active');
          }
        }}
        onSlideChange={(swiper: SwiperClass) => {
          const currentNumber = swiper.realIndex;
          const currentElement = document.querySelector('.js-swiper-pagination-current') as HTMLElement;
          if (currentElement) {
            currentElement.innerText = (currentNumber + 1).toString();
          }
          const paginationItems = document.querySelectorAll('.js-visual-pagination .visual-pagination__item');
          paginationItems.forEach((item, index) => {
            if (index === currentNumber) {
              item.classList.add('is--active');
            } else {
              item.classList.remove('is--active');
            }
          });
        }}
      >
        <SwiperSlide className="swiper-slide main-visual-swiper-slide main-visual-swiper-slide1">
          <MainSlideBg1 className="main-slide-bg"></MainSlideBg1>
          <MainSlideText className="main-slide-text">
            <Title className="tit">미래를 밝히는 빛</Title>
            <Description className="desc">대산파워텍이 함께 합니다</Description>
          </MainSlideText>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide main-visual-swiper-slide main-visual-swiper-slide2">
          <MainSlideBg2 className="main-slide-bg"></MainSlideBg2>
          <MainSlideText className="main-slide-text">
            <Title className="tit">미래를 밝히는 빛</Title>
            <Description className="desc">대산파워텍이 함께 합니다</Description>
          </MainSlideText>
        </SwiperSlide>
        <VisualPaginationWrap className="visual-pagination-wrap">
          <VisualPagination className="visual-pagination js-visual-pagination">
            <VisualPaginationItem className="visual-pagination__item" data-paging-index="1">
              <svg className="circle-progress" width="40" height="40" viewBox="0 0 40 40">
                <circle className="frame" cx="20" cy="20" r="18" strokeWidth="2" />
                <circle className="bar" cx="20" cy="20" r="18" strokeWidth="2" />
                <circle className="point" cx="20" cy="20" r="4" strokeWidth="2" />
              </svg>
            </VisualPaginationItem>
            <VisualPaginationItem className="visual-pagination__item" data-paging-index="2">
              <svg className="circle-progress" width="40" height="40" viewBox="0 0 40 40">
                <circle className="frame" cx="20" cy="20" r="18" strokeWidth="2" />
                <circle className="bar" cx="20" cy="20" r="18" strokeWidth="2" />
                <circle className="point" cx="20" cy="20" r="4" strokeWidth="2" />
              </svg>
            </VisualPaginationItem>
          </VisualPagination>
          <ButtonArea className="button-area">
            <div className="swiper-button-prev"></div>
            <span className="swiper-pagination-current js-swiper-pagination-current">1</span>
            <span className="bar"></span>
            <span className="swiper-pagination-total js-swiper-pagination-total">3</span>
            <div className="swiper-button-next"></div>
          </ButtonArea>
        </VisualPaginationWrap>
      </Swiper>
      <div className="visual-scroll bounce-mouse" data-aos="fade" data-aos-delay="500"></div>
    </MainWrap>
  );
});

const Title = styled.p`
  opacity: 0;
  padding-bottom: 12px;
  transform: translate3d(0, 100px, 0);
  font-size: 60px;
  line-height: 1.221052;
  text-transform: uppercase;
  font-weight: bold;
  @media screen and (max-width:768px) {
    font-size: 36px;
  }
`;

const Description = styled.p`
  opacity: 0;
  transform: translate3d(0, 100px, 0);
  font-size: 28px;
  line-height: 1.5;
  text-align: center;
  @media screen and (max-width:768px) {
    font-size: 20px;
  }
`;

const MainSlideText = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding-top: 46vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  color: #ffffff;
`;

const MainSlideBg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  -webkit-transform: scale(1.2, 1.2) rotate(0.002deg);
  transform: scale(1.2, 1.2) rotate(0.002deg);
  -webkit-transition: transform 2000ms ease-in;
  -moz-transition: transform 2000ms ease-in;
  -o-transition: transform 2000ms ease-in;
  transition: transform 2000ms ease-in;
`;

const MainSlideBg1 = styled(MainSlideBg)`
  background-image: url("/assets/images/main/visual_bg1.png");
`;

const MainSlideBg2 = styled(MainSlideBg)`
  background-image: url("/assets/images/main/visual_bg2.png");
`;

const MainWrap = styled.div`
  position: relative;
  height: 100vh;
  .mainSwiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    overflow: hidden;
  }
  .swiper-slide-active {
    ${Title}, ${Description} {
      opacity: 1;
      transform: translateZ(0);
      transition-property: opacity, transform;
      transition-timing-function: ease-in-out;
      transition-duration: 1.8s;
    }
    ${Description} {
      transition-delay: 1.2s;
    }
    ${MainSlideBg} {
      -webkit-transform: scale(1.01, 1.01) rotate(0.002deg);
      transform: scale(1.01, 1.01) rotate(0.002deg);
    }
  }
`;

const MainSwiper = styled(Swiper)`
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const VisualPaginationWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.6vw;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VisualPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VisualPaginationItem = styled.div`
  width: 50px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .circle-progress {
    transform: rotate(-90deg);
  }
  .frame, .bar {
    fill: none;
    stroke: none;
  }
  .frame {
    display: none;
    stroke: rgba(255, 255, 255, 0.2);
  }
  .point {
    fill: #ffffff;
  }
  &.is--active {
    .circle-progress {
      .frame {
        stroke: rgba(255, 255, 255, 0.2);
      }
      .bar {
        stroke-dashoffset: 113;
        animation: dash 5s linear forwards;
        animation-play-state: running;
        stroke: rgba(255, 255, 255, 0.2);
        stroke: rgba(255, 255, 255, 0.2);
        stroke-linecap: round;
        stroke-dasharray: 113;
      }
    }
  }
  @keyframes dash {
    to {
      stroke: #ffffff;
      stroke-dashoffset: 0;
    }
  }
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .swiper-button-prev, .swiper-button-next {
    padding: 0;
    box-sizing: border-box;
    position: static;
    display: block;
    width: 17px;
    height: 17px;
    margin: 0;
    background-image: url(/assets/images/main/icon-arrow_prev.png);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    display: none;
  }
  .swiper-button-next {
    left: auto;
    right: 0;
    background-image: url(/assets/images/main/icon-arrow_next.png);
  }
  .swiper-pagination-current {
    position: static;
    color: #fff;
    font-family: "Montserrat", "NotoSansKR", sans-serif, "Malgun Gothic", "맑은 고딕";
    padding-left: 5px;
  }
  .bar {
    display: block;
    width: 1px;
    height: 12px;
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.5);
  }
  .swiper-pagination-total{
    position: static;
    color: rgba(255, 255, 255, 0.5);
    font-family: inherit;
    font-family: "Montserrat", "NotoSansKR", sans-serif, "Malgun Gothic", "맑은 고딕";
    padding-right: 5px;
  }
`;

export default MainSection;
