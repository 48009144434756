import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useLocation } from "react-router-dom";
import CommonMeta from "./CommonMeta";
import menuData from "../data/menu.json";

interface SubTopContProps {
  name?: string;
  subname?: string;
}

const SubTopCont = () => {
  const location = useLocation();
  const englishRef = useRef<HTMLParagraphElement | null>(null);
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  const findNameAndSubname = (path: string) => {
    for (const menu of menuData) {
      if (menu.path === path) {
        return { name: menu.name, subname: menu.subname };
      }
      for (const submenu of menu.submenu || []) {
        if (submenu.path === path) {
          return { name: submenu.name, subname: submenu.subname };
        }
      }
    }
    return { name: "대산파워텍", subname: "" };
  };

  const { name, subname } = findNameAndSubname(location.pathname);

  useEffect(() => {
    const subTopBg = document.querySelector('.sub-top-bg');
    const subTopMenu = document.querySelector('.sub-top-menu');
    const subTopEnglish = englishRef.current;
    const subTopTitle = titleRef.current;

    if (subTopBg) {
      subTopBg.classList.add('active');
    }

    if (subTopMenu) {
      subTopMenu.classList.add('active');
    }

    if (subTopEnglish && subTopTitle) {
      [subTopEnglish, subTopTitle].forEach(el => {
        el.classList.add('is--active');
      });
    }

    const splittingText = (object: HTMLElement) => {
      object.innerHTML = Array.from(object.textContent || '').map((char, index) => `<em class="char" style="animation-delay: ${index * 0.1}s">${char}</em>`).join('');
    };

    if (englishRef.current) {
      splittingText(englishRef.current);
    }

    if (titleRef.current) {
      setTimeout(() => {
        if (titleRef.current) { // null 체크 추가
          splittingText(titleRef.current);
        }
      }, 500); // 0.5초 딜레이
    }
  }, []);

  return (
    <Container>
      <CommonMeta />
      <SubTopBg className="sub-top-bg">
        <SubTopEnglish
          className="sub-top__english js-word-split"
          data-splitting
          data-css-property="animation"
          data-speed="0.1"
          data-speed-delay="0.3"
          ref={englishRef}
        >
          {name}
        </SubTopEnglish>
      </SubTopBg>
      <SubContentArea>
        <SubContentContent>
          <SubTopTitle
            className="sub-top__title js-word-split"
            data-splitting
            data-css-property="animation"
            data-speed="0.1"
            data-speed-delay="0.6"
            ref={titleRef}
          >
            {subname}
          </SubTopTitle>
        </SubContentContent>
      </SubContentArea>
    </Container>
  );
};

const textActiveAnimation02 = keyframes`
  70% {
    color: #6555f8;
  }
  100% {
    color: #fff;
    opacity: 1;
  }
`;

const textActiveAnimation03 = keyframes`
  70% {
    color: #fff;
  }
  100% {
    color: #000;
    opacity: 1;
  }
`;

const Container = styled.div`
  // 필요에 따라 스타일 추가
`;

const SubTopBg = styled.div`
  width: 100%;
  position: relative;
  height: 420px;
  padding-top: 40px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F0ECE9;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/assets/images/sub/sub-top-bg.jpg");
  margin: 0 auto;
  justify-content: center;
  box-sizing: border-box;

  &.active {
  }
  @media screen and (max-width:768px) {
    height: 320px;
  }
`;

const SubTopEnglish = styled.div`
  font-size: 69px;
  font-family: "minerva-modern", sans-serif, "Malgun Gothic", "맑은 고딕";
  color: #2e2868;
  line-height: 1;
  order: 2;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  &.is--active {
    opacity: 1;
    transform: translateZ(0);
  }
  & .char {
    display: inline-block;
    opacity: 0;
    font-family: inherit;
    animation: ${textActiveAnimation02} 0.5s forwards;
  }
  &.is--active .char {
    opacity: 1;
  }
  &::after {
    content: '';
    opacity: 0;
    width: 0;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.accentColor};
  }
  @media screen and (max-width:768px) {
    font-size: 44px;
  }
`;

const SubContentArea = styled.div`
  width: 1200px;
  margin: auto;
  @media screen and (max-width:768px) {
    width: 100%;
    padding: 0 6vw;
  }
`;

const SubContentContent = styled.div`
  // 스타일 정의
`;

const SubTopTitle = styled.h2`
  position: relative;
  display: block;
  font-size: 36px;
  font-weight: 400;
  color: #333;
  order: 1;
  text-indent: -1px;
  margin-top: 50px;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  @media screen and (max-width:768px) {
    font-size: 24px;
    margin-top: 20px;
  }
  & .char {
    font-family: "minerva-modern", sans-serif, "Malgun Gothic", "맑은 고딕";
    display: inline-block;
    opacity: 0;
    animation: ${textActiveAnimation03} 0.5s forwards;
  }

  &.is--active .char {
    opacity: 1;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #BFBFBF;
    margin: 20px 0 40px;
    @media screen and (max-width:768px) {
      margin-top: 10px;
    }
  }

  &.is--active {
    opacity: 1;
    transform: translateZ(0);

    span {
      animation: 2s 4s ease-in-out ${textActiveAnimation03} forwards;
    }
  }
`;

export default SubTopCont;
