import { DefaultTheme } from 'styled-components';

export const darkTheme:DefaultTheme = {
  bgColor: '#2f3640',
  textColor: '#fff',
  accentColor: '#8e44ad',
  mainColor: '#fff',
}

export const LightTheme:DefaultTheme = {
  bgColor: '#fafafb',
  textColor: '#000',
  accentColor: '#8e44ad',
  mainColor: '#2e2868',
}