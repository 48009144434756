import styled from "styled-components";
import Header from "../components/header";
import Footer from "../components/footer";
import menuData from '../data/menu.json';
import { useEffect, useRef } from "react";
import MainSection from "../components/MainSection";
import InfoSection from "../components/InfoSection";
import FieldSection from './../components/FiledSection';
import LocationSection from "../components/LocationSection";
import CommonMeta from "../components/CommonMeta";

const Main = () => {
  const mainSectionRef = useRef<HTMLDivElement | null>(null);
  const infoSectionRef = useRef<HTMLDivElement | null>(null);
  const fieldSectionRef = useRef<HTMLDivElement | null>(null);
  const locationSectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const sections = [
      { ref: mainSectionRef, offset: + 200 },
      { ref: infoSectionRef, offset: + 200 },
      { ref: fieldSectionRef, offset: + 200 },
      { ref: locationSectionRef, offset: + 200 },
    ];

    const scrollMotion = () => {
      const currentScroll = window.scrollY;
      sections.forEach(section => {
        if (section.ref.current) {
          const sectionTop = section.ref.current.getBoundingClientRect().top + window.scrollY;
          const sectionHeight = window.innerHeight - sectionTop + currentScroll - section.offset;
          //console.log(`currentScroll: ${currentScroll}, sectionHeight: ${sectionHeight}`); 
          if (currentScroll > sectionTop - section.offset) {
            section.ref.current.classList.add('is-active');
          } else {
            section.ref.current.classList.remove('is-active');
          }
        }
      });
    };

    window.addEventListener('scroll', scrollMotion);

    return () => {
      window.removeEventListener('scroll', scrollMotion);
    };
  }, []);

  return (
    <Container>
      <CommonMeta />
      <Header menuData={menuData} />
      <MainSection ref={mainSectionRef} />
      <InfoSection ref={infoSectionRef} />
      <FieldSection ref={fieldSectionRef} />
      <LocationSection ref={locationSectionRef} />
      <Footer />
    </Container>
  );
};

const Container = styled.div``;

export default Main;
